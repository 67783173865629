import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Communication',
  components: {},
  setup: function setup(props, ctx) {
    var activeNames = ref(['1']);
    return {
      activeNames: activeNames
    };
  }
});