import { ref, Ref, computed } from 'vue'
import {
  IAttendanceParams,
  IAttendanceInfoItem
} from '@/service/work/attendanceAnalysis/type'
import { getDepAttendanceInfo } from '@/service/work/attendanceAnalysis/index'

export default function useDepAttendanceInfo(params: Ref<IAttendanceParams>): [
  Ref<
    {
      require_count: number
      absent_count: number
      dep_name: string
      attendance: string
    }[]
  >,
  () => Promise<void>
] {
  const depAttendanceInfo = ref<IAttendanceInfoItem[]>([])

  // 查询时间范围内停机详情
  const getDepAttendanceInfoAsync = async () => {
    const res = await getDepAttendanceInfo(params.value)
    depAttendanceInfo.value = res.data.list
  }

  return [depAttendanceInfo, getDepAttendanceInfoAsync]
}
