import hyRequest from '@/service'
import {
  IAttendance,
  IAttendanceInfo,
  IAttendanceParams,
  IAttendanceMonthInfo,
  IAbsentUser
} from '@/service/work/attendanceAnalysis/type'
import { IRequestResult } from '@/service/types'

enum DashboardApi {
  getAttendanceStatistics = '/oa/attendanceStatistics',
  getDepAttendanceInfo = '/oa/depAttendanceInfo',
  getDepAttendanceMonthInfo = '/oa/depAttendanceMonthInfo',
  getAbsentUserList = '/oa/absentUserList'
}

// 获取实时考勤
export function getAttendanceStatistics(
  params: IAttendanceParams
): Promise<IRequestResult<IAttendance>> {
  return hyRequest.post<IRequestResult<IAttendance>>({
    url: DashboardApi.getAttendanceStatistics,
    data: params
  })
}
//本日各部门出勤情况
export function getDepAttendanceInfo(
  params: IAttendanceParams
): Promise<IRequestResult<IAttendanceInfo>> {
  return hyRequest.post<IRequestResult<IAttendanceInfo>>({
    url: DashboardApi.getDepAttendanceInfo,
    data: params
  })
}

//部门考勤月度分析
export function getDepAttendanceMonthInfo(
  params: IAttendanceParams
): Promise<IRequestResult<IAttendanceMonthInfo>> {
  return hyRequest.post<IRequestResult<IAttendanceMonthInfo>>({
    url: DashboardApi.getDepAttendanceMonthInfo,
    data: params
  })
}
//未出勤人员信息
export function getAbsentUserList(
  params: IAttendanceParams
): Promise<IRequestResult<IAbsentUser>> {
  return hyRequest.post<IRequestResult<IAbsentUser>>({
    url: DashboardApi.getAbsentUserList,
    data: params
  })
}
