import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cc503916"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "detail mb-2"
};
var _hoisted_2 = {
  class: "detail-item flex align-center justify-between"
};
var _hoisted_3 = {
  class: "name"
};
var _hoisted_4 = {
  class: "money"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.user_name), 1), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.status), 1)])]);
}