import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { defineComponent, onMounted, ref, onUnmounted } from 'vue';
import useAbsentUser from './hooks/useAbsentUser';
import dayjs from 'dayjs';
import UserListItem from './cpns/UserListItem.vue';
import DeptListItem from './cpns/DeptListItem.vue';
import { useRoute } from 'vue-router';
import localCache from '@/utils/localCache';
export default defineComponent({
  name: 'AbsentPersonnel',
  components: {
    UserListItem: UserListItem,
    DeptListItem: DeptListItem
  },
  setup: function setup(props, ctx) {
    var confrimDate = function confrimDate(val) {
      params.value.date = dayjs(val).format('YYYY-MM-DD');
      getAbsentUserListAsync();
    };
    var route = useRoute();
    var params = ref({
      date: dayjs().format('YYYY-MM-DD'),
      type: ''
    });
    var deptName = ref('');
    if (route.params.deptName === 'stsj') {
      localCache.setItem('deptName', route.params.deptName);
    }
    var localCacheDeptName = localCache.getItem('deptName');
    if (localCacheDeptName) {
      deptName.value = localCacheDeptName;
      params.value.type = deptName.value;
    }
    var activeName = ref();
    var _useAbsentUser = useAbsentUser(params),
      _useAbsentUser2 = _slicedToArray(_useAbsentUser, 2),
      absentUserList = _useAbsentUser2[0],
      getAbsentUserListAsync = _useAbsentUser2[1];
    onMounted(function () {
      getAbsentUserListAsync();
    });
    onUnmounted(function () {
      localCache.deleteItem('deptName');
    });
    return {
      absentUserList: absentUserList,
      activeName: activeName,
      confrimDate: confrimDate,
      deptName: deptName
    };
  }
});