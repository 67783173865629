import "core-js/modules/es.number.constructor.js";
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UserListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props, ctx) {
    return {};
  }
});