import { ref, Ref } from 'vue'
import { getAbsentUserList } from '@/service/work/attendanceAnalysis'
import {
  IAbsentUserItem,
  IAbsentUserListItem,
  IAttendanceParams
} from '@/service/work/attendanceAnalysis/type'

export default function useAbsentUser(
  params: Ref<IAttendanceParams>
): [Ref<IAbsentUserItem[] | IAbsentUserListItem[]>, () => Promise<void>] {
  const absentUserList = ref<IAbsentUserItem[] | IAbsentUserListItem[]>([])
  const getAbsentUserListAsync = async () => {
    const res = await getAbsentUserList(params.value)
    absentUserList.value = res.data.list
  }

  return [absentUserList, getAbsentUserListAsync]
}
