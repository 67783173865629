import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IUserAttendanceInfo, IUserInfoNew } from './type'

// 个人详情
export function getUserData(params: {
  mobile: string
}): Promise<IRequestResult<IUserInfoNew>> {
  return hyRequest.post<IRequestResult<IUserInfoNew>>({
    url: '/communication/getUserInfoNew',
    data: params
  })
}

export function getUserData1(params: {
  userId: string
}): Promise<IRequestResult<IUserAttendanceInfo>> {
  return hyRequest.post<IRequestResult<IUserAttendanceInfo>>({
    url: '/communication/getUserAttendanceInfo',
    data: params
  })
}

export function updateOrganizationNew(params: {
  id: string
  responsibility: string
}): Promise<IRequestResult<string>> {
  return hyRequest.post<IRequestResult<string>>({
    url: '/communication/updateOrganizationNew',
    data: params
  })
}
