import { ref, Ref, computed } from 'vue'
import {
  IAttendanceParams,
  IAttendanceMonthItem
} from '@/service/work/attendanceAnalysis/type'
import { getDepAttendanceMonthInfo } from '@/service/work/attendanceAnalysis/index'

export default function useDepAttendanceMonthInfo(
  params: Ref<IAttendanceParams>
): [
  Ref<
    {
      leave_count: number
      dep_name: string
      late_count: number
      attendance: number
    }[]
  >,
  () => Promise<void>
] {
  const depAttendanceMonthInfo = ref<IAttendanceMonthItem[]>([])

  // 查询时间范围内停机详情
  const getDepAttendanceMonthInfoAsync = async () => {
    const res = await getDepAttendanceMonthInfo(params.value)
    depAttendanceMonthInfo.value = res.data.list
  }

  return [depAttendanceMonthInfo, getDepAttendanceMonthInfoAsync]
}
