import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IAttendanceParams,
  IAttendance
} from '@/service/work/attendanceAnalysis/type'
import { getAttendanceStatistics } from '@/service/work/attendanceAnalysis/index'

export default function useRealTimeAttendance(params: Ref<IAttendanceParams>): [
  ComputedRef<
    {
      num: number
      desc: string
      routeName: string
    }[]
  >,
  () => Promise<void>
] {
  const attendanceTotal = ref<IAttendance>({
    no_sign_count: 0,
    leave_count: 0,
    leave_days_count: 0,
    leave_early_count: 0,
    require_count: 0,
    absent_count: 0,
    late_count: 0,
    attendance_count: 0,
    absent_from_work: 0
  })

  // 查询时间范围内停机详情
  const getAttendanceAsync = async () => {
    const res = await getAttendanceStatistics(params.value)
    attendanceTotal.value = res.data
  }
  const attendanceData = computed(() => {
    return [
      {
        num: attendanceTotal.value.require_count,
        desc: '总考勤人数',
        routeName: ''
      },
      {
        num: attendanceTotal.value.attendance_count,
        desc: '已打卡人数',
        routeName: ''
      },
      {
        num: attendanceTotal.value.absent_count,
        desc: '未打卡人数',
        routeName: 'AbsentPersonnel'
      },
      {
        num: attendanceTotal.value.late_count,
        desc: '迟到',
        routeName: ''
      },
      {
        num: attendanceTotal.value.leave_early_count,
        desc: '早退',
        routeName: ''
      },
      {
        num: attendanceTotal.value.leave_count,
        desc: '请假',
        routeName: ''
      }
    ]
  })
  return [attendanceData, getAttendanceAsync]
}
