import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-35d63803"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "rankListItem flex align-center"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "weight"
};
var _hoisted_4 = {
  class: "detail"
};
var _hoisted_5 = {
  class: "name"
};
var _hoisted_6 = {
  class: "money"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_collapse_item = _resolveComponent("van-collapse-item");
  return _openBlock(), _createBlock(_component_van_collapse_item, {
    name: _ctx.index,
    class: "mb-2"
  }, {
    title: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.dep_name), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.absent_count), 1)])];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.absentUserList, function (iten, indey) {
        return _openBlock(), _createElementBlock("div", {
          key: indey,
          class: "detail-item flex align-center justify-between"
        }, [_createElementVNode("span", _hoisted_5, _toDisplayString(iten.user_name), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(iten.status), 1)]);
      }), 128))])];
    }),
    _: 1
  }, 8, ["name"]);
}